.container {
    padding-top: 30px;
    width: 50%;
    margin: auto;
  }
  
  .input {
    padding: 5px 15px;
    width: 300px;
  }
  
  .user-list {
    margin-top: 30px;
  }
  
  .user {
    list-style: none;
    margin: 10px 0;
    padding: 10px;
    background: #eee;
    display: flex;
    justify-content: space-between;
  }
  
  .user-id {
    color: red;
    margin-right: 20px;
  }
  
  .user-owner_name {
    color: blue;
  }
  
  .user-Last_Modified {
    margin-left: auto;
  }
  .data{
    border: 1px sold black ;
  }